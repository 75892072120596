import React, { useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { useStandardsList } from 'sdk';

import { SearchField } from 'pages/Teachers/shared';
import { useStandardsV2 } from 'pages/Teachers/shared/hooks';
import { getStandardOptionLabel } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardDialogV2 = ({
  onClose,
  onSave,
  selectedItems = [],
  saveButtonLabel = 'Save'
}) => {
  const [selectedStandards, setSelectedStandards] = useState(selectedItems);
  const [anscestorId, setAnscestorId] = useState(null);
  const [selectedStandardSet, setSelectedStandardSet] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);

  const selectedStandardIds = useMemo(
    () => _.map(selectedStandards, 'id'),
    [selectedStandards]
  );

  const { standards, setStandardSearchValue, standardsLoading } =
    useStandardsV2(selectedStandardIds, anscestorId);

  const { data: standardSets, loading: standardsSetsLoading } =
    useStandardsList({ params: { retrieve_root_standards: true } });

  const { data: grades, loading: gradesLoading } = useStandardsList({
    params: { parent_id: selectedStandardSet }
  });

  const { data: domains, loading: domainsLoading } = useStandardsList({
    params: { parent_id: selectedGrade }
  });

  const [, , isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 767
  });

  const debouncedSetStandardSearchValue = _.debounce(
    (event) => setStandardSearchValue(event.target.value),
    300
  );

  const onStandardInputChange = (event) => {
    event.persist();

    debouncedSetStandardSearchValue(event);
  };

  const handleStandardSetChange = (event) => {
    const standardSetID = event.target.value;
    setSelectedStandardSet(standardSetID);
    setAnscestorId(standardSetID);
    setSelectedGrade(null);
    setSelectedDomain(null);
  };

  const handleGradeChange = (event) => {
    const gradeId = event.target.value;
    if (gradeId) {
      setSelectedGrade(gradeId);
      setAnscestorId(gradeId);
      setSelectedDomain(null);
    } else {
      setSelectedGrade(null);
      setSelectedDomain(null);
      setAnscestorId(selectedStandardSet);
    }
  };

  const handleDomainChange = (event) => {
    const domainId = event.target.value;
    if (domainId) {
      setSelectedDomain(domainId);
      setAnscestorId(domainId);
    } else {
      setSelectedDomain(null);
      setAnscestorId(selectedGrade);
    }
  };

  const onOptionChange = (event, values) => {
    setSelectedStandards(values);
  };

  const minStandardLevel = _.min(_.map(standards, 'level'));

  let searchPlaceholder = '';

  if (_.isEmpty(selectedStandards)) {
    searchPlaceholder = isMobile
      ? 'Search Standards'
      : 'Search Standards (e.g., RL.7.2)';
  }

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      fullWidth
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <Box display="flex" justifyContent="flex-end" marginBottom="30px">
        <Button
          color="pink"
          className={styles.actionButtons}
          onClick={() => {
            onSave({ selectedStandards });
            onClose();
          }}
        >
          {saveButtonLabel}
        </Button>
      </Box>
      {!standardsSetsLoading && (
        <Box display="flex" justifyContent="center">
          <FormControl className={styles.selectForm}>
            <InputLabel id="select-standard-set-label">
              Select Standard Set
            </InputLabel>
            <Select
              labelId="select-standard-set-label"
              id="select-standard-set"
              value={selectedStandardSet}
              onChange={handleStandardSetChange}
            >
              <MenuItem key={`standard-set-option-none`} value={null}>
                ----
              </MenuItem>
              {standardSets?.results.map((standardSet) => (
                <MenuItem
                  key={`standard-set-option-${standardSet.id}`}
                  value={standardSet.id}
                >
                  {standardSet.short_description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={`${styles.selectForm} ${styles.middleSelectForm}`}
          >
            <InputLabel id="select-grade-label">Select Grade</InputLabel>
            <Select
              labelId="select-grade-label"
              id="select-grade"
              value={selectedGrade}
              onChange={handleGradeChange}
              disabled={selectedStandardSet === null || gradesLoading}
            >
              <MenuItem key={`grade-option-none`} value={null}>
                ----
              </MenuItem>
              {grades?.results?.map((grade) => (
                <MenuItem key={`grade-option-${grade.id}`} value={grade.id}>
                  {grade.short_description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.selectForm}>
            <InputLabel id="select-domain-label">Select Domain</InputLabel>
            <Select
              labelId="select-domain-label"
              id="select-domain"
              value={selectedDomain}
              onChange={handleDomainChange}
              disabled={
                selectedStandardSet === null ||
                selectedGrade === null ||
                domainsLoading
              }
            >
              <MenuItem key={`domain-option-none`} value={null}>
                ----
              </MenuItem>
              {domains?.results?.map((domain) => (
                <MenuItem key={`domain-option-${domain.id}`} value={domain.id}>
                  {domain.code} - {domain.short_description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box>
        <SearchField
          multiple
          autoFocus
          autoHighlight
          options={standards}
          defaultValue={selectedItems}
          getOptionLabel={(option) =>
            getStandardOptionLabel({ standard: option })
          }
          className={styles.searchField}
          disableClearable
          onInputChange={onStandardInputChange}
          onChange={onOptionChange}
          getOptionSelected={(option, value) => option.id === value.id} // The options we're passing are list of objects. We need to define the equiality function here.
          loading={standardsLoading}
          noOptionsText="No standards"
          placeholder={searchPlaceholder}
          PopperComponent={({ children }) => <div>{children}</div>} // Setting the PopperComponent to be a div instead of the mui Popper, so that we can render the options in the modal body
          PaperComponent={({ children }) => <div>{children}</div>} // Setting the PaperComponent to be a div instead of the mui Paper, so that we do not have an outline in the options menu
          renderTags={(selectedTags, getTagProps) =>
            selectedTags.map((tag, index) => {
              const { onDelete } = getTagProps({ index });

              return (
                <Chip
                  size="small"
                  onDelete={onDelete}
                  key={index}
                  classes={{ root: styles.selectedStandard }}
                  deleteIcon={<CloseIcon className={styles.blueIcon} />}
                  label={
                    <Typography variant="B-Text-3" color={colors.blue3}>
                      <strong>{_.get(tag, 'code', 'short-description')}</strong>
                    </Typography>
                  }
                ></Chip>
              );
            })
          }
          renderOption={(option) => {
            const standardPaddingLeft =
              (option.level - minStandardLevel + 1) * 10;

            return (
              <Typography
                variant="B-Text-2"
                color={colors.grey2}
                style={{
                  paddingLeft: `${standardPaddingLeft}px`
                }}
              >
                {getStandardOptionLabel({ standard: option })}
              </Typography>
            );
          }}
          classes={{ option: styles.option }}
        />
      </Box>
    </Dialog>
  );
};

export default StandardDialogV2;
