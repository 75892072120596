import React, { useMemo, useState } from 'react';

import cx from 'classnames';

import MasteryLevelDetail from 'pages/Teachers/InsightsV2/components/MasteryLevelDetail';

import Typography from 'components/Typography';

import { ReactComponent as ArrowDownIcon } from './assets/arrow-down-icon.svg';
import { ReactComponent as ArrowUpIcon } from './assets/arrow-up-icon.svg';
import styles from './styles.module.scss';

const MasteryLevelGroup = ({
  title,
  icon,
  students,
  percentage,
  nextSteps,
  exemplarUrls,
  color,
  description,
  refetchOverview,
  trackGroupExpandEvent,
  trackStudentWorkDialogClickEvent,
  trackNextStepCompleteEvent,
  isMisconception = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const disableClick = useMemo(() => students.length === 0, [students]);

  const toggleExpanded = () => {
    if (!disableClick) {
      if (!isExpanded) trackGroupExpandEvent();
      setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    }
  };

  return (
    <div>
      <div
        className={cx(styles.mainContainer, {
          [styles.disabled]: disableClick
        })}
        onClick={toggleExpanded}
      >
        <div className={styles.summary}>
          <div className={styles.titleContainer}>
            {icon}
            <Typography variant="H-TEXT-2">
              {title} - {students.length} ({(percentage * 100).toFixed(0)}%)
            </Typography>
          </div>
          <div className={styles.titleContainer}>
            {description && (
              <>
                <div
                  className={styles.rectangle}
                  style={{ backgroundColor: color }}
                ></div>
                <Typography
                  variant="B-TEXT-4"
                  color={color}
                  className={styles.description}
                >
                  {description}
                </Typography>
              </>
            )}
          </div>
        </div>

        {isExpanded && !disableClick && <ArrowUpIcon />}
        {!isExpanded && !disableClick && <ArrowDownIcon />}
      </div>
      {isExpanded && (
        <MasteryLevelDetail
          misconceptionName={isMisconception ? title : ''}
          students={students}
          nextSteps={nextSteps}
          exemplarUrls={exemplarUrls}
          refetchOverview={refetchOverview}
          trackStudentWorkDialogClickEvent={trackStudentWorkDialogClickEvent}
          trackNextStepCompleteEvent={trackNextStepCompleteEvent}
        />
      )}
    </div>
  );
};

export default MasteryLevelGroup;
