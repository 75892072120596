import React, { useContext, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { isNil } from 'lodash';
import { isEmpty } from 'lodash';

import EmptyDataState from 'pages/Teachers/InsightsV2/components/EmptyDataState';
import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MasteryLevelGroup from 'pages/Teachers/InsightsV2/components/MasteryLevelGroup';
import { colors } from 'theme/palette';
import {
  trackMixpanelEvent,
  trackMixpanelPageView
} from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as InsufficientIcon } from './assets/insufficient-icon.svg';
import styles from './styles.module.scss';

// Splits a single string label into multiple lines based on a specified maximum line length.
const splitLabelIntoMultiline = (label, maxLineLength) => {
  const chunks = [];

  const labelParts = label.split(/[, ]+/);
  labelParts.forEach((labelPart) => {
    if (isEmpty(chunks)) chunks.push(labelPart);
    else {
      const lastElement = chunks.pop();
      const newElement = `${lastElement} ${labelPart}`;

      if (newElement.length <= maxLineLength) {
        chunks.push(newElement);
      } else {
        chunks.push(lastElement);
        chunks.push(labelPart);
      }
    }
  });

  return chunks;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MisconceptionsBarChart = ({ barChartData }) => {
  const data = {
    labels: barChartData.map((item) =>
      splitLabelIntoMultiline(item.name.split('(')[0], 15)
    ),
    datasets: [
      {
        label: 'Misconceptions',
        backgroundColor: [
          colors.blue1,
          colors.blue2,
          colors.blue3,
          colors.blue4,
          colors.blue5,
          colors.blue6,
          colors.blue7,
          colors.blue8,
          colors.blue9
        ],
        borderRadius: 10,
        data: barChartData.map((item) => item.students.length)
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const percentage = barChartData[index].student_percentage;

            return `${(percentage * 100).toFixed(0)}%`;
          },
          title: function () {
            return '';
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          }
        }
      }
    },
    aspectRatio: 9
  };

  return <Bar data={data} options={options} />;
};

const MisconceptionsOverview = ({ data, isLoading, refetchOverview }) => {
  const user = useContext(UserContext);

  useEffect(() => {
    trackMixpanelPageView(user.id, {
      feature: 'insights',
      view: 'misconceptions'
    });
  }, [user]);

  const trackMisconceptionsMasteryLevelGroupExpand = ({ groupName }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        view: 'misconceptions',
        button: groupName
      }
    );
  };

  const trackMisconceptionsStudentWorkDialogClick = ({ groupName }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        view: 'misconceptions',
        button: 'student work',
        group: groupName
      }
    );
  };

  const trackMisconceptionsNextStepsMarkAsCompleted = ({
    completedNextStep
  }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
      {
        feature: 'insights',
        view: 'misconceptions',
        button: 'complete',
        next_step: completedNextStep
      }
    );
  };

  if (isLoading && isNil(data)) return <LoadingSkeleton />;

  if (!isLoading && isEmpty(data)) return <EmptyDataState />;

  const sortedMisconceptionData = data.sort(
    (a, b) => b.student_percentage - a.student_percentage
  );

  return (
    <div className={styles.container}>
      <Typography variant="H-TEXT-2" style={{ textTransform: 'none' }}>
        To help you get started, we&apos;ve grouped your students by
        mastery—those excelling (Celebrate), those progressing (Support), and
        those needing targeted support (Intervention). Our AI helps get you
        started, but tweak groups as needed to celebrate progress and reteach
        effectively with our intervention strategies.
      </Typography>
      <MisconceptionsBarChart barChartData={sortedMisconceptionData} />
      {sortedMisconceptionData.map(
        (
          { name, student_percentage, students, next_steps, exemplar_urls },
          index
        ) => {
          return (
            <MasteryLevelGroup
              key={index}
              title={name}
              icon={<InsufficientIcon />}
              students={students}
              percentage={student_percentage}
              nextSteps={next_steps}
              exemplarUrls={exemplar_urls}
              refetchOverview={refetchOverview}
              trackGroupExpandEvent={() =>
                trackMisconceptionsMasteryLevelGroupExpand({ groupName: name })
              }
              trackStudentWorkDialogClickEvent={() =>
                trackMisconceptionsStudentWorkDialogClick({ groupName: name })
              }
              trackNextStepCompleteEvent={
                trackMisconceptionsNextStepsMarkAsCompleted
              }
              isMisconception={true}
            />
          );
        }
      )}
    </div>
  );
};

export default MisconceptionsOverview;
