import { useEffect, useMemo, useRef, useState } from 'react';

import { BASE_URL } from 'config/urls';
import _ from 'lodash';

import { parseAndPrepareStudentPortfolioDateFilters } from 'pages/Students/utils';
import {
  callUrl,
  get,
  post,
  requestSdk,
  useFetchV2 as useFetch
} from 'utils/sdk';
import { stringifyParams } from 'utils/urls';

export const getStandards = (params) =>
  callUrl(get, `${BASE_URL}/v1/standards/`, params);

export const usePagination = ({
  initialData,
  isLoading: initialIsLoading = false
}) => {
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [results, setResults] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);

  useEffect(() => {
    setNextUrl(_.get(initialData, 'next', null));
    setResults(_.get(initialData, 'results', []));
  }, [initialData]);

  useEffect(() => {
    setIsLoading(initialIsLoading);
  }, [initialIsLoading]);

  const hasMore = useMemo(() => !_.isNil(nextUrl), [nextUrl]);

  const fetchMore = async () => {
    if (nextUrl) {
      setIsLoading(true);

      const response = await get(nextUrl);

      if (response.status.toString().startsWith('2')) {
        setNextUrl(response.data.next);
        setResults((prevResults) => [...prevResults, ...response.data.results]);
      } else {
        setNextUrl(null);
        // Add some toast or something else.
      }

      setIsLoading(false);
    }
  };

  return { results, hasMore, fetchMore, isLoading };
};

export const useAssignmentResults = (selectedSectionId) =>
  useFetch(
    selectedSectionId
      ? [`/v1/trackers/assignments/${selectedSectionId}/results/`]
      : undefined
  );

export const useStandardsForStudentPortfolio = ({ studentId, filters }) =>
  useFetch(
    filters
      ? [`/v1/trackers/student-portfolio/${studentId}/standards/`]
      : undefined,
    filters
  );

// This is the same thing as the previous method but it refetches when `filters` changes, instead of
// having the need to call the exported refetch method.
export const useStandardsForStudentPortfolioWithFilters = ({
  studentId,
  filters
}) => {
  const formattedFilters = Object.assign(
    _.omit(filters, ['startDate', 'endDate']),
    parseAndPrepareStudentPortfolioDateFilters({ filters })
  );

  return useFetch(
    [
      `/v1/trackers/student-portfolio/${studentId}/standards/`,
      formattedFilters
    ],
    formattedFilters
  );
};

export const useStudentSections = ({ studentId }) =>
  useFetch([`/v1/trackers/student-portfolio/sections/${studentId}/`]);

export const useStudentAssignments = ({ studentId }) =>
  useFetch([`/v1/trackers/student-portfolio/assignments-names/${studentId}/`]);

export const useCurrentSchoolYear = () =>
  useFetch([`/v1/core/current-school-year/`]);

export const studentPortfolioTriggerAssignmentsExport = async (
  studentId,
  requestParams
) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/student-portfolio/trigger-export-assignments/${studentId}/`,
      requestParams
    )
  );

export const studentPortfolioTriggerAssignmentsWithStandardsExport = async (
  studentId,
  requestParams
) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/student-portfolio/trigger-export/assignments-for-standards/${studentId}/`,
      requestParams
    )
  );

export const studentPortfolioGetAssignmentsExportUrl = (exportUUID) =>
  `${BASE_URL}/v1/trackers/student-portfolio/export-url/${exportUUID}/`;

export const getStrategiesOverviewExportUrl = ({
  sectionId,
  grade,
  unit,
  lesson
}) => {
  return `${BASE_URL}/v1/tags/${sectionId}/strategies/export/?grade=${grade}&unit=${unit}&lesson=${lesson}`;
};

export const useStudentPortfolio = ({ studentId, filters }) => {
  const formattedFilters = Object.assign(
    _.omit(filters, ['startDate', 'endDate']),
    parseAndPrepareStudentPortfolioDateFilters({ filters })
  );

  return useFetch(
    _.isEmpty(filters)
      ? undefined
      : [`/v1/trackers/student-portfolio/${studentId}/`, formattedFilters],
    formattedFilters
  );
};

export const useStudent = ({ studentId }) =>
  useFetch([`/v1/students/${studentId}/detail/`]);

export const useStudentConceptMasteryData = ({ studentId, params }) =>
  useFetch(
    studentId && params.year
      ? [`/v1/students/${studentId}/concept-mastery/`, params]
      : undefined,
    params
  );

export const useStudentLearningTrendsData = ({ studentId, params }) =>
  useFetch(
    studentId && params.year
      ? [`/v1/students/${studentId}/learning-trends/`, params]
      : undefined,
    params
  );

export const useStudentStrengthsData = ({ studentId, params }) =>
  useFetch(
    studentId && params.year
      ? [`/v1/students/${studentId}/strengths/`, params]
      : undefined,
    params
  );

export const addAssignmentComment = async ({ studentId, assignmentId, data }) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/students/${studentId}/assignments/${assignmentId}/add-comment/`,
      data
    )
  );

export const useCurriculums = ({ parentId, getGrandchildren }) =>
  useFetch(['/v1/curriculum/', parentId], {
    parent_id: parentId,
    get_grandchildren: getGrandchildren
  });

export const useAcceleratedCurriculums = ({ parentId, getGrandchildren }) =>
  useFetch(['/v1/curriculum/accelerated/', parentId], {
    parent_id: parentId,
    get_grandchildren: getGrandchildren
  });

export const useAssignmentTemplate = ({
  assignmentTemplateId,
  params
} = {}) => {
  return useFetch(
    assignmentTemplateId
      ? [
          `/v1/curriculum/assignment-templates/${assignmentTemplateId}/retrieve/`,
          params
        ]
      : null,
    params || {}
  );
};

export const createTrackerFromAssignmentTemplate = async ({
  assignmentTemplateId,
  data
}) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/create/from-assignment-template/${assignmentTemplateId}/`,
      data
    )
  );

export const useMeetingBoards = ({ selectedMeetingId, queryParams }) =>
  useFetch(
    selectedMeetingId
      ? [`/v1/meetings/${selectedMeetingId}/boards/`, queryParams]
      : undefined
  );

export const assingAssignmentToBulkUpload = async ({ bulkUploadId, data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/assign-assignment/`, data)
  );

export const useAssignmentsNamesList = ({ data }) =>
  useFetch([`/v1/trackers/assignments/names-list/`, data], data);

export const useRecentlyCreatedAssignments = ({ params }) => {
  return useFetch([
    `/v2/trackers/assignments/recent/list/?` + stringifyParams(params)
  ]);
};

export const useMeetingBoardShares = ({ meetingBoardId }) =>
  useFetch([`/v1/meetings/boards/${meetingBoardId}/shares/`]);

export const meetingBoardUpdate = async ({ meetingBoardId, data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/boards/${meetingBoardId}/update/`, data)
  );

export const meetingBoardDelete = async ({ meetingBoardId }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/boards/${meetingBoardId}/delete/`)
  );

export const useUsersInUserSections = () =>
  useFetch(['/v1/sections/users-in-sections/']);

export const useYears = () => useFetch(['/v1/core/years/active/']);

export const useUserSections = ({ params }) =>
  useFetch([`/v1/sections/user/list/`, params], params);

export const useTrackersOverview = ({ params, enabled }) => {
  const random = useRef(Date.now());

  return useFetch(
    enabled ? ['/v1/trackers/overview/', params, random] : undefined,
    params
  );
};

export const useOrganizations = () => useFetch([`/v1/organizations/`]);

export const useClosestTask = () =>
  useFetch([`/v1/trackers/assignments/get-closest-task/`]);

export const useUserSectionsCount = () =>
  useFetch(['/v1/sections/count-for-user/']);

export const useSessionsStatistics = () =>
  useFetch(['/v1/trackers/shared-tracker-instance-sections/statistics/']);

export const useSubmittedTasksStatistics = () =>
  useFetch(['/v1/trackers/assignments/submitted-tasks-statistics/']);

export const useGoogleAuthToken = () =>
  useFetch(['/v1/integrations/google/auth-token/']);

export const useGoogleLastSync = () =>
  useFetch(['/v1/integrations/google/classroom/last-sync/']);

export const useCleverLastSync = () => useFetch(['/v1/clever/last-sync/']);

export const googleManualSync = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/integrations/google/classroom/sync-manually/`)
  );

export const addCommentToStudent = ({
  trackerInstanceSectionId,
  studentId,
  data
}) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/instances/sections/${trackerInstanceSectionId}/students/${studentId}/add-comment/`,
      data
    )
  );

export const taskStatusUpdate = ({ trackerScoreResponseId, data }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/task/${trackerScoreResponseId}/update-status/`,
      data
    )
  );

export const sectionUpdate = ({ sectionId, data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/sections/${sectionId}/update/`, data));

export const studentGroupCreate = ({ data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/sections/student-groups/create/`, data)
  );

export const useSchoolUsers = ({ params }) =>
  useFetch(
    params ? ['/v1/schools/schools-school-users/', params] : undefined,
    params
  );

export const createBulkUpload = async ({ data }) =>
  await requestSdk(() => post(`${BASE_URL}/v1/bulk-uploads/create/`, data));

export const createBulkUploadMultiplePDFs = async ({ data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/multiple-pdf-create/`, data)
  );

export const useBulkUploadDetail = ({ bulkUploadId }) => {
  const random = useRef(Date.now());

  return useFetch(
    bulkUploadId
      ? [`/v1/bulk-uploads/${bulkUploadId}/retrieve/`, random]
      : undefined
  );
};

export const bulkUploadAssignmentAssign = ({ bulkUploadId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/assignment-assign/`, data)
  );

export const useBulkUploadFirstAssignmentItems = ({ bulkUploadId }) =>
  useFetch([
    `/v1/bulk-uploads/${bulkUploadId}/first-assignment-items-retrieve/`
  ]);

export const bulkUploadFirstAssignmentItems = ({ bulkUploadId }) =>
  requestSdk(() =>
    get(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/first-assignment-items-retrieve/`,
      {}
    )
  );

export const bulkUploadPagesConfirm = ({ bulkUploadId, rotationCount }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/pages-confirm/`, {
      rotation_count: rotationCount
    })
  );

export const bulkUploadPagesConfirmCancel = ({ bulkUploadId }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/pages-confirm-cancel/`)
  );

export const selectBulkUploadNameRegion = ({ bulkUploadId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/region-select/`, data)
  );

export const bulkUploadAutomaticNameRegion = ({
  bulkUploadId,
  rotationCount
}) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/automatic-region-select/`,
      {
        rotation_count: rotationCount
      }
    )
  );

export const useBulkUploadGroups = ({ bulkUploadId, params }) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/groups/list/`, params], params);

export const useBulkUploadStudents = ({
  unmatchedOnly = false,
  bulkUploadId
}) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/students/list/`, unmatchedOnly], {
    unmatched_only: unmatchedOnly
  });

export const excludeBulkUploadImage = ({ bulkUploadId, bulkUploadImageId }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/images/${bulkUploadImageId}/exclude/`
    )
  );

export const bulkUploadImageUpdate = ({
  bulkUploadId,
  bulkUploadImageId,
  data
}) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/images/${bulkUploadImageId}/update/`,
      data
    )
  );

export const useAssignmenTasks = ({ assignmentId, data }) =>
  useFetch(
    assignmentId
      ? [`/v2/trackers/assignments/${assignmentId}/task/list/`, data]
      : undefined,
    data
  );

export const useBulkUploadSubmissionsCount = ({ bulkUploadId }) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/submissions-count/`]);

export const finishBulkUpload = ({ bulkUploadId }) =>
  requestSdk(() => post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/finish/`));

export const uploadAssignmentFeedback = ({ data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v2/trackers/assignments/task/upload-feedback/`, data)
  );

export const useAssignmentStudentWork = ({ assignmentId }) =>
  useFetch(
    assignmentId
      ? [`/v2/trackers/assignments/${assignmentId}/student-work/`]
      : undefined
  );

export const useUserTodayMeetings = () =>
  useFetch(['/v1/meetings/count-for-today/']);

export const addMeetingAgendas = ({ meetingId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/add-agenda/`, data)
  );

export const meetingSharesCreate = ({ meetingId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/shares/create/`, data)
  );

export const useMeetingInfo = ({
  invitationIdentifier,
  params,
  errorHandlers
}) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/`]
      : undefined,
    params,
    errorHandlers
  );

export const useTeamMeetings = ({ teamId }) =>
  useFetch(teamId ? [`/v1/meetings/teams/${teamId}/meetings/`] : undefined);

export const updateMeetingSharingEnabled = ({ meetingId, data }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/meetings/${meetingId}/update-is-sharing-enabled/`,
      data
    )
  );

export const useMeetingInvitationDetail = ({ invitationIdentifier }) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/meeting-invitation-details/`]
      : undefined
  );

export const useMeetingInvitationSchools = ({ invitationIdentifier }) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/meeting-schools/`]
      : undefined
  );

export const acceptMeetingInvitation = ({ invitationIdentifier }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${invitationIdentifier}/accept-invitation/`)
  );

export const completeProfile = async ({ data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/users/complete-profile/`, data));

export const useTeamUpcomingMeetings = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/upcoming/`, params]
      : undefined,
    params
  );

export const useTeamPastMeetings = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/past/`, params]
      : undefined,
    params
  );

export const useTeamMeetingsPerUser = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/`, params]
      : undefined,
    params
  );

export const useTeam = ({ teamId }) =>
  useFetch(teamId ? [`/v1/meetings/teams/${teamId}/`] : undefined);

export const createProfile = ({ data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/walkthrough/create-profile/`, data));

export const useAssignmentSectionsList = ({ trackerId }) =>
  useFetch(
    trackerId ? [`/v1/trackers/assignments/${trackerId}/sections/`] : undefined
  );

export const useTagAssignmentsList = () =>
  useFetch([`/v1/tags/assignments/list/`]);

export const useTeacherSections = () => useFetch([`/v1/sections/user/list/`]);

export const useSectionGrades = ({ sectionId }) =>
  useFetch(sectionId ? [`/v1/tags/section/${sectionId}/grades/`] : undefined);

export const useSectionUnits = ({ sectionId, params }) =>
  useFetch(
    sectionId ? [`/v1/tags/section/${sectionId}/units/`, params] : undefined,
    params
  );

export const useSectionLessons = ({ sectionId, params }) =>
  useFetch(
    sectionId ? [`/v1/tags/section/${sectionId}/lessons/`, params] : undefined,
    params
  );

export const useSectionStrategies = ({ sectionId, params }) =>
  useFetch(
    sectionId ? [`/v1/tags/${sectionId}/strategies/`, params] : undefined,
    params
  );

export const useSectionRubrics = ({ sectionId, params }) =>
  useFetch(
    sectionId ? [`/v1/tags/${sectionId}/rubrics/`, params] : undefined,
    params
  );

export const useFiltersData = ({ sectionId }) =>
  useFetch(sectionId ? [`/v1/tags/${sectionId}/filters/`] : undefined);

export const useOrganizationsSchools = () =>
  useFetch([`/v1/organizations/org-schools-for-user/`]);

export const useTeacherSectionsForSchool = ({ schoolId, yearId }) =>
  useFetch(
    schoolId && yearId
      ? [
          `/v1/sections/${schoolId}/school-user-sections/`,
          { school_year: yearId }
        ]
      : undefined,
    { school_year: yearId }
  );

export const useAssignmentTagTemplateStrategies = ({
  assignmentTagTemplateId
}) =>
  useFetch(
    assignmentTagTemplateId
      ? [
          `/v1/tags/assignment-tag-templates/${assignmentTagTemplateId}/strategies/`
        ]
      : undefined
  );

export const deleteAssignmentAudio = ({ audioFileId }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${audioFileId}/delete-audio/`
  );

export const usePathwaysProfileQuestions = () => {
  return useFetch([`/v1/pathways/profile-question/list/`]);
};

export const pathwaysProfileAnswersSubmit = ({ data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/profile-answers/submit/`, data)
  );

export const usePathwaysResults = () =>
  useFetch([`/v1/pathways/results/list/`]);

export const logout = () => callUrl(post, `${BASE_URL}/v1/auth/logout/`);

export const usePathwaysPersonalGoals = () =>
  useFetch([`/v1/pathways/personal-goal/list/`]);

export const pathwaysPersonalGoalSubmit = ({ data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/pathways/personal-goal/submit/`, data));

export const pathwaysGetDailyReflection = async (params) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/pathways/daily-reflection/`, params)
  );

export const pathwaysDailyReflectionSubmit = async ({ data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/daily-reflection/submit/`, data)
  );

export const useFamilyStudentList = () =>
  useFetch(['/v1/family/students/list']);

export const useFamilyMemberDetails = () =>
  useFetch(['/v1/family/member/details']);

export const updateFamilyMember = async ({ data }) =>
  await requestSdk(() => post(`${BASE_URL}/v1/family/member/update`, data));

export const useFamilyStudentAssignmentDatesList = (params) => {
  return useFetch(['/v1/family/assignment/dates/list'], params);
};

export const useFamilyStudentAssignmentsList = ({ studentId, params }) => {
  return useFetch(
    studentId
      ? [`/v1/family/student/${studentId}/assignments/list?date=${params.date}`]
      : undefined
  );
};

export const useFamilyStudentDetailAssignment = async ({
  studentId,
  assignmentId
}) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/family/students/${studentId}/assignments/${assignmentId}/`
    )
  );

export const me = async () => {
  return await requestSdk(() => get(`${BASE_URL}/v1/auth/me/`));
};

export const usePathwaysWeeklyProgress = ({ timezone }) => {
  return useFetch(
    timezone ? [`/v1/pathways/weekly-progress/`, { timezone }] : undefined,
    { timezone }
  );
};

export const pathwaysGetArticlesForDate = async (params) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/pathways/articles-for-date/`, params)
  );

export const useChatbotChatHistory = () =>
  useFetch([`/v1/pathways/chat/history/`]);

export const chatbotMessageLike = ({ chat_message_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/chat/${chat_message_id}/like/`)
  );

export const chatbotMessageDisLike = ({ chat_message_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/chat/${chat_message_id}/dislike/`)
  );

export const articleLike = ({ id }) =>
  requestSdk(() => post(`${BASE_URL}/v1/pathways/articles/${id}/like/`));

export const articleDisLike = ({ id }) =>
  requestSdk(() => post(`${BASE_URL}/v1/pathways/articles/${id}/dislike/`));

export const usePathwaysOverallResponseInsightOfTheDay = () =>
  useFetch([`/v1/pathways/overall-response-insight/`]);

export const checkOverallResponseInsightOfTheDay = async ({ date }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/overall-response-insight/mark-checked/`, {
      date
    })
  );

export const usePathwaysMiniLessonProgress = (params) =>
  useFetch(
    params.timezone
      ? [`/v1/pathways/mini-lesson/progress/`, params]
      : undefined,
    params
  );

export const pathwaysGetDailyMiniLesson = async (params) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/pathways/mini-lesson-for-date/`, params)
  );

export const miniLessonLike = ({ id }) =>
  requestSdk(() => post(`${BASE_URL}/v1/pathways/mini-lesson/${id}/like/`));

export const miniLessonDisLike = ({ id }) =>
  requestSdk(() => post(`${BASE_URL}/v1/pathways/mini-lesson/${id}/dislike/`));

export const pathwaysGetTipOfTheDay = async (params) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/pathways/tip-of-the-day/`, params)
  );

export const tipOfTheDayLike = ({ tip_of_the_day_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/tip-of-the-day/${tip_of_the_day_id}/like/`)
  );

export const tipOfTheDayDisLike = ({ tip_of_the_day_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/tip-of-the-day/${tip_of_the_day_id}/dislike/`)
  );

export const tipOfTheDayTried = ({ tip_of_the_day_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/tip-of-the-day/${tip_of_the_day_id}/tried/`)
  );

export const miniLessonComplete = ({ mini_lesson_id }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/mini-lesson/${mini_lesson_id}/complete/`)
  );

export const trackArticleCheck = ({ date }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/articles/mark-checked/`, { date })
  );

export const pathwaysUserGetJourneyProgress = async () =>
  await requestSdk(() => get(`${BASE_URL}/v1/pathways/user-journey-progress/`));

export const pathwaysJourneyStart = async () =>
  await requestSdk(() => post(`${BASE_URL}/v1/pathways/user-journey-start/`));

export const pathwaysJourneyComplete = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/user-journey-complete/`)
  );

export const pathwaysJourneyCompletePersonalGoals = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/user-journey-complete-personal-goals/`)
  );

export const pathwaysJourneyCompleteStartTrackingGoals = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/pathways/user-journey-complete-start-tracking-goals/`)
  );

export const pathwaysGetDailyMemeForDate = async (params) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/pathways/daily-meme-for-date/`, params)
  );

export const familyAssignmentDetail = async ({ studentId, assignmentId }) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/family/students/${studentId}/assignments/${assignmentId}/`
    )
  );

export const useTrackerInstanceSections = ({ params, enabled }) => {
  const random = useRef(Date.now());

  return useFetch(
    enabled
      ? ['/v1/tags/tracker-instance-sections/', params, random]
      : undefined,
    params
  );
};

export const useOverallResponseOverview = ({ trackerInstanceSectionId }) =>
  useFetch(
    trackerInstanceSectionId
      ? [`/v1/tags/overall-response/${trackerInstanceSectionId}/overview/`]
      : undefined
  );

export const useMisconceptionsOverview = ({ trackerInstanceSectionId }) =>
  useFetch(
    trackerInstanceSectionId
      ? [`/v1/tags/misconceptions/${trackerInstanceSectionId}/overview/`]
      : undefined
  );

export const useWorkTagImageMasteryLevelData = ({ workTagImageId }) =>
  useFetch(
    workTagImageId
      ? [`/v1/tags/work-tag-image/${workTagImageId}/mastery-level/`]
      : undefined
  );

export const useWorkTagImagesAnnotations = ({ params }) =>
  useFetch(
    params.work_tag_images && params.work_tag_images.length > 0
      ? [`/v1/tags/work-tag-images/annotations/`, params]
      : undefined,
    params
  );

export const workTagImageUpdateOverallResponseMastery = async ({
  workTagImageId,
  data
}) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/tags/work-tag-image/${workTagImageId}/overall-response/update/`,
      data
    )
  );

export const workTagImageUpdateMisconceptions = async ({
  workTagImageId,
  data
}) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/tags/work-tag-image/${workTagImageId}/misconceptions/update/`,
      data
    )
  );

export const useTrackerInstanceSectionDetail = ({ trackerInstanceSectionId }) =>
  useFetch(
    trackerInstanceSectionId
      ? [
          `/v1/tags/insights/tracker-instance-section/${trackerInstanceSectionId}/`
        ]
      : undefined
  );

export const trackerInstanceSectionToggleCompleteNextStep = async ({
  trackerInstanceSectionToNextStepId
}) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/next-steps/complete/${trackerInstanceSectionToNextStepId}`
    )
  );

export const getInsightsOverviewExportURL = ({ trackerInstanceSectionId }) => {
  const url = `${BASE_URL}/v1/tags/${trackerInstanceSectionId}/overview/export/`;

  return url;
};

export const useNextStepsPanelDetails = ({ params }) =>
  useFetch([`/v1/next-steps/details`, params], params);

export const useTrackerInstanceSectionCheckPreannotationsStatus = ({
  trackerInstanceSectionId
}) =>
  useFetch(
    trackerInstanceSectionId
      ? [
          `/v1/tags/tracker-instance-section/${trackerInstanceSectionId}/check-preannotations-status/`
        ]
      : undefined
  );

export const useStandardsList = ({ params }) =>
  useFetch([`/v1/standards/`, params], params);
