import React, { useContext, useEffect, useMemo, useState } from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  useWorkTagImageMasteryLevelData,
  useWorkTagImagesAnnotations
} from 'sdk';

import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MisconceptionMasteryLevel from 'pages/Teachers/InsightsV2/components/MisconceptionMasteryLevel';
import OverallResponseMasteryLevel from 'pages/Teachers/InsightsV2/components/OverallResponseMasteryLevel';
import { trackMixpanelPageView } from 'utils/integrations/mixpanel';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const MULTIPAGE_TAGGING_ENABLED =
  process.env.REACT_APP_MULTIPAGE_TAGGING_ENABLED === 'true';

const StudentSummaryDialog = ({
  misconceptionName,
  studentName,
  workTagImageId,
  refetchOverview,
  isSummaryDialogIsOpen,
  handleNextIconClick,
  handlePreviousIconClick,
  onClose,
  workTagImageIds
}) => {
  const user = useContext(UserContext);
  const [currentWorkTagImageIndex, setCurrentWorkTagImageIndex] = useState(0);

  useEffect(() => {
    trackMixpanelPageView(user.id, {
      feature: 'insights',
      view: 'student work'
    });
  }, [user]);

  const {
    data,
    isLoading,
    refetch: refetchMasteryLevel
  } = useWorkTagImageMasteryLevelData({
    workTagImageId
  });

  const { data: annotationsData, refetch: refetchAnnotations } =
    useWorkTagImagesAnnotations({
      params: { work_tag_images: workTagImageIds }
    });

  const images = useMemo(() => {
    if (annotationsData) {
      if (misconceptionName.length === 0) {
        return annotationsData;
      }
      return annotationsData.filter((annotation) => {
        return annotation['misconception_mastery_level']['misconceptions'].some(
          (misconception) => misconception['name'] === misconceptionName
        );
      });
    }
    return [];
  }, [annotationsData, misconceptionName]);

  const currentImage = useMemo(() => {
    if (!data && !MULTIPAGE_TAGGING_ENABLED) {
      return;
    }

    if (MULTIPAGE_TAGGING_ENABLED && images.length === 0) {
      return;
    }

    if (MULTIPAGE_TAGGING_ENABLED) {
      return images[currentWorkTagImageIndex];
    }
    return data;
  }, [currentWorkTagImageIndex, data, images]);

  const imagesCount = useMemo(() => images?.length || 0, [images]);

  const handleLeftArrowClick = () => {
    setCurrentWorkTagImageIndex((currentWorkTagImageIndex) => {
      if (currentWorkTagImageIndex > 0) return currentWorkTagImageIndex - 1;
      return currentWorkTagImageIndex;
    });
  };

  const handleRightArrowClick = () => {
    setCurrentWorkTagImageIndex((currentWorkTagImageIndex) => {
      if (currentWorkTagImageIndex < imagesCount - 1)
        return currentWorkTagImageIndex + 1;
      return currentWorkTagImageIndex;
    });
  };

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        open={isSummaryDialogIsOpen}
        onClose={onClose}
        className={styles.dialog}
        classes={{ paper: styles.paperRoot }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.headingContainer}>
          <span className={styles.studentHeading}>
            <IconButton onClick={handlePreviousIconClick}>
              <SvgIcon className={styles.svgIcon} component={ChevronLeftIcon} />
            </IconButton>
            <Typography className={styles.studentName} variant="H-TEXT-1">
              {studentName}
            </Typography>
            <IconButton onClick={handleNextIconClick}>
              <SvgIcon
                className={styles.svgIcon}
                component={ChevronRightIcon}
              />
            </IconButton>
          </span>
          <ButtonGroup className={styles.imageButtonGroup}>
            <Button
              disabled={currentWorkTagImageIndex === 0}
              onClick={handleLeftArrowClick}
            >
              &lt;
            </Button>

            <Button>
              Page {currentWorkTagImageIndex + 1} / {imagesCount}
            </Button>

            <Button
              disabled={currentWorkTagImageIndex === imagesCount - 1}
              onClick={handleRightArrowClick}
            >
              &gt;
            </Button>
          </ButtonGroup>
        </div>

        <div className={styles.dialogContent}>
          <div className={styles.leftCard}>
            <div className={styles.imageContainer}>
              {!isLoading && currentImage && (
                <Image
                  src={currentImage.work_tag_image_url}
                  className={styles.image}
                />
              )}
            </div>
          </div>
          <div className={styles.rightCard}>
            {isLoading && <LoadingSkeleton />}
            {!isLoading && currentImage && (
              <>
                <OverallResponseMasteryLevel
                  workTagImageId={
                    MULTIPAGE_TAGGING_ENABLED
                      ? currentImage.work_tag_image_id
                      : workTagImageId
                  }
                  masteryLevel={currentImage.overall_response_mastery_level}
                  refetchOverview={refetchOverview}
                  refetchMasteryLevel={
                    MULTIPAGE_TAGGING_ENABLED
                      ? refetchAnnotations
                      : refetchMasteryLevel
                  }
                />
                <MisconceptionMasteryLevel
                  workTagImageId={
                    MULTIPAGE_TAGGING_ENABLED
                      ? currentImage.work_tag_image_id
                      : workTagImageId
                  }
                  masteryLevel={currentImage.misconception_mastery_level}
                  refetchOverview={refetchOverview}
                  refetchMasteryLevel={
                    MULTIPAGE_TAGGING_ENABLED
                      ? refetchAnnotations
                      : refetchMasteryLevel
                  }
                />
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StudentSummaryDialog;
