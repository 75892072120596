import React, { useCallback, useContext, useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import assignmentCreate from 'images/create-from-scratch.svg';
import assignmentCopy from 'images/reuse-existing.svg';
import assignmentTemplate from 'images/use-a-template.svg';
import _ from 'lodash';
import { useAssignmentsNamesList } from 'sdk';

import { SearchField } from 'pages/Teachers/shared';
import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import { MuiAutocompleteChangeReasons } from 'utils/constants';

import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';
import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const TooltipTitle = () => (
  <Typography variant="B-Text-2">
    Leverage AI for Illustrative Mathematics Cool Downs
  </Typography>
);

const AssignmentCreateSelectFlow = ({
  setIsFlowSelected,
  setIsCreateNewSelected,
  setIsCreateTemplateSelected,
  isThreeModalAssignmentCreationEnabled
}) => {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const { open: openCreateAssignmentDialog } = useContext(
    AssignmentCreateDialogContext
  );
  const { data: assignmentsNames } = useAssignmentsNamesList({
    data: { include_prior_years: true }
  });

  const handleCreateFromScratch = useCallback(() => {
    openCreateAssignmentDialog({
      mode: AssignmentDialogModes.CREATE
    });
  }, [openCreateAssignmentDialog]);

  const handleUseTemplate = useCallback(() => {
    setIsFlowSelected(true);
    setIsCreateTemplateSelected(true);
  }, [setIsFlowSelected, setIsCreateTemplateSelected]);

  const handleAssignmentChange = useCallback(
    (event, value, reason) => {
      if (_.isNil(value)) return;

      setSelectedAssignment(value);

      if (reason === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        openCreateAssignmentDialog({
          mode: AssignmentDialogModes.COPY,
          selectedAssignment: value
        });
      } else {
        openCreateAssignmentDialog({
          mode: AssignmentDialogModes.CREATE,
          selectedAssignment: value
        });
      }
    },
    [openCreateAssignmentDialog, setSelectedAssignment]
  );

  const onCreateNewAssignmentFlowSelect = () => {
    setIsFlowSelected(true);
    setIsCreateTemplateSelected(false);
    setIsCreateNewSelected(true);
  };

  const onCreateOldAssignmentFlowSelect = () => {
    setIsFlowSelected(true);
    setIsCreateTemplateSelected(true);
    setIsCreateNewSelected(false);
  };

  if (!isThreeModalAssignmentCreationEnabled) {
    return (
      <div className={styles.oldFlowWrapper}>
        <Tooltip placement="bottom-end" title={<TooltipTitle />}>
          <Button
            onClick={onCreateOldAssignmentFlowSelect}
            color="pink"
            data-intercom-target="Assignment Create Dialog Student Work Insights Button"
          >
            Student work insights
          </Button>
        </Tooltip>
        <Button
          onClick={onCreateNewAssignmentFlowSelect}
          color="pink"
          data-intercom-target="Assignments Create Dialog Do It Yourself Button"
        >
          Do it yourself
        </Button>
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <main className={styles.cardsGrid}>
        <div className={styles.card}>
          <Typography variant="h6" className={styles.cardTitle}>
            CREATE FROM SCRATCH
          </Typography>
          <div className={styles.iconWrapper}>
            <img
              className={styles.icon}
              src={assignmentCreate}
              alt="Assignment Create"
            />
          </div>
          <Typography variant="body2" className={styles.cardDescription}>
            Use our assignment editor to create an assignment.
          </Typography>
          <button
            onClick={handleCreateFromScratch}
            className={styles.buttonSecondary}
            data-intercom-target="Create Start Scratch Button"
          >
            GET STARTED →
          </button>
        </div>

        <div className={styles.card}>
          <Typography variant="H-TEXT-2" className={styles.cardTitle}>
            USE A TEMPLATE
          </Typography>
          <div className={styles.iconWrapper}>
            <img
              className={styles.icon}
              src={assignmentTemplate}
              alt="Assignment Template"
            />
          </div>
          <Typography variant="body2" className={styles.cardDescription}>
            Using a set curriculum? Leverage AI and save time with a template!
          </Typography>
          <button
            onClick={handleUseTemplate}
            className={styles.buttonPrimary}
            data-intercom-target="Create Start Template Button"
          >
            GET STARTED →
          </button>
        </div>

        <div className={styles.card}>
          <Typography variant="h6" className={styles.cardTitle}>
            REUSE EXISTING
          </Typography>
          <div className={styles.iconWrapper}>
            <img
              className={styles.icon}
              src={assignmentCopy}
              alt="Assignment Copy"
            />
          </div>
          <Typography variant="body2" className={styles.cardDescription}>
            Modify a copy of an existing assignment.
          </Typography>
          <div className={styles.searchWrapper}>
            <SearchField
              value={selectedAssignment}
              options={assignmentsNames || []}
              getOptionLabel={(assignment) => assignment.name}
              onChange={handleAssignmentChange}
              nooptionstext={
                <Typography variant="body2">No Assignments</Typography>
              }
              placeholder="Search your assignments"
              data-intercom-target="Create Start Reuse Button"
            />
          </div>
        </div>
      </main>
    </section>
  );
};

export default AssignmentCreateSelectFlow;
