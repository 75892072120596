import { ChevronDown, Plus, Upload, X } from 'lucide-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Formik } from 'formik';
import _ from 'lodash';
import {
  createTrackerFromAssignmentTemplate,
  useAssignmentTemplate
} from 'sdk';
import * as Yup from 'yup';

import TeacherAssignmentCreate from 'pages/Teachers/Assignments/Create';
import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import {
  assignmentDetail,
  trackerCreate,
  trackerUpdate
} from 'pages/Teachers/shared/AssignmentDialog/sdk';
import AttachmentDropzone from 'pages/Teachers/shared/AttachmentDropzone';
import AttachmentList from 'pages/Teachers/shared/AttachmentList';
import AttachmentUploadOverlay from 'pages/Teachers/shared/AttachmentUploadOverlay';
import {
  buildStepperFormDataForCreate,
  buildStepperFormDataForUpdate
} from 'utils/assignments';
import { AssignmentTaskValueTypes } from 'utils/constants';
import { setFormErrors } from 'utils/forms';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';

import AssignmentDialogContext from 'components/AssignmentDialogContext';
import Dialog from 'components/Dialog';
import StandardDialogV2 from 'components/StandardDialogV2';

import { VALUES_OPTIONS } from '../../constants';
import styles from './styles.module.scss';

const FIRST_PAGE = {
  standards: [],
  count: 1
};

const validationSchema = Yup.object().shape({
  assignmentName: Yup.string().required('Assignment name is required'),
  description: Yup.string(),
  tasks: Yup.array().of(
    Yup.object().shape({
      standards: Yup.array(),
      count: Yup.number().min(1, 'Must have at least 1 page')
    })
  ),
  rubric: Yup.string().required('Please select a rubric'),
  pageCount: Yup.string()
    .required('Number of pages is required')
    .test(
      'valid-pages',
      'Must be at least 1 page',
      (value) => value && parseInt(value) > 0
    ),
  pageType: Yup.string().required('Please select page type'),
  attachments: Yup.array()
});

const StepperAssignmentCreateForm = ({
  trackerId,
  mode = AssignmentDialogModes.CREATE,
  onCreateSuccess,
  onUpdateSuccess,
  defaultAssignmentName = '',
  assignmentTemplateId,
  isAcceleratedCurriculum,
  onClose
}) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isFromTemplate, setIsFromTemplate] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [activeTab, setActiveTab] = useState('create');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [activeSettingsTab, setActiveSettingsTab] = useState('attachments');

  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog
  } = useDialog();

  const { isAssignmentDialogOpened, setIsAssignmentDialogOpened } = useContext(
    AssignmentDialogContext
  );

  const templateResult = useAssignmentTemplate({
    assignmentTemplateId: assignmentTemplateId || null,
    params: { is_accelerated: isAcceleratedCurriculum }
  });

  const { data: assignmentTemplate, isLoading: isTemplateLoading } =
    templateResult || {};

  const effectiveTemplateData = assignmentTemplateId
    ? assignmentTemplate
    : null;
  const effectiveLoading = assignmentTemplateId ? isTemplateLoading : false;

  const fetchAssignmentDetail = async ({ trackerId }) => {
    const { data, success, errors } = await assignmentDetail(trackerId);
    if (success) {
      return data;
    }
    notifyErrors(errors);
    return null;
  };

  const initialize = useCallback(async () => {
    let newInitialValues = {
      assignmentName: defaultAssignmentName,
      description: '',
      tasks: [FIRST_PAGE],
      rubric: AssignmentTaskValueTypes.RUBRIC,
      pageType: '1',
      pageCount: '1',
      attachments: []
    };

    const mapAssignmentData = (assignment) => {
      const taskCount = assignment.tasks?.length || 0;
      const pageType = taskCount <= 4 ? String(taskCount) : 'custom';
      const pageCount = String(taskCount);
      const valueType =
        assignment.tasks?.[0]?.value_type || AssignmentTaskValueTypes.RUBRIC;
      const isTemplateData = 'title' in assignment;

      return {
        assignmentName: isTemplateData
          ? assignment.title
          : assignment.name || '',
        description: assignment.description || '',
        tasks: assignment.tasks || FIRST_PAGE,
        rubric: valueType,
        pageType: pageType,
        pageCount: pageCount,
        attachments: assignment.attachments || []
      };
    };

    if (assignmentTemplateId && effectiveTemplateData) {
      setIsFromTemplate(true);
      newInitialValues = {
        ...mapAssignmentData(effectiveTemplateData),
        isAcceleratedCurriculum
      };
    } else if (trackerId) {
      try {
        const assignment = await fetchAssignmentDetail({ trackerId });
        if (assignment) {
          const isCopyMode = mode === AssignmentDialogModes.COPY;

          if (!_.isNil(assignment.assignment_template_id)) {
            setIsFromTemplate(true);
          }

          const mappedValues = mapAssignmentData(assignment);
          newInitialValues = {
            ...mappedValues,
            assignmentName: isCopyMode
              ? `Copy of ${mappedValues.assignmentName}`
              : mappedValues.assignmentName
          };
        }
      } catch (error) {
        console.error('Error fetching assignment:', error);
        notifyErrors(error);
      }
    }

    setInitialValues(newInitialValues);
  }, [
    trackerId,
    mode,
    defaultAssignmentName,
    assignmentTemplateId,
    effectiveTemplateData,
    isAcceleratedCurriculum
  ]);

  const createTracker = useCallback(
    async ({ formData, onError }) => {
      try {
        if (assignmentTemplateId) {
          const { success, errors, data } =
            await createTrackerFromAssignmentTemplate({
              assignmentTemplateId,
              data: formData
            });

          if (success) {
            await onCreateSuccess(data);
          } else {
            onError(errors);
          }

          return success;
        }

        const { success, errors, data } = await trackerCreate(formData);

        if (success) {
          await onCreateSuccess(data);
        } else {
          onError(errors);
        }

        return success;
      } catch (error) {
        onError(error);
        return false;
      }
    },
    [onCreateSuccess, assignmentTemplateId]
  );

  const updateTracker = useCallback(
    async ({ formData, onError }) => {
      try {
        const { success, errors } = await trackerUpdate({
          trackerId,
          data: formData
        });

        if (success) {
          await onUpdateSuccess();
        } else {
          onError(errors);
        }

        return success;
      } catch (error) {
        onError(error);
        return false;
      }
    },
    [trackerId, onUpdateSuccess]
  );

  const onStandardsSelected = ({
    selectedStandards,
    formikTasks,
    setFieldValue
  }) => {
    formikTasks.forEach((task, taskIndex) => {
      const objectiveStandardsFieldName = `tasks.${taskIndex}.standards`;
      setFieldValue(objectiveStandardsFieldName, selectedStandards);
    });
    closeStandardDialog();
  };

  const onSubmit = async (values, { setFieldError, setSubmitting }) => {
    try {
      setSubmitAttempted(true);
      setSubmitting(true);

      const formData =
        mode === AssignmentDialogModes.UPDATE
          ? buildStepperFormDataForUpdate({ values })
          : buildStepperFormDataForCreate({ values });

      const onError = (errors) => setFormErrors(errors, setFieldError);

      if (mode === AssignmentDialogModes.UPDATE) {
        return await updateTracker({ formData, onError });
      }

      return await createTracker({ formData, onError });
    } catch (error) {
      console.error('Form submission error:', error);
      setFieldError(
        'submit',
        'An unexpected error occurred. Please try again.'
      );
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!initialValues && (!assignmentTemplateId || !effectiveLoading)) {
      initialize();
    }
  }, [initialValues, initialize, assignmentTemplateId, effectiveLoading]);

  if (!initialValues || effectiveLoading) return null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => {
        const showError = (fieldName) => submitAttempted && errors[fieldName];
        const assignmentStandards = _.chain(values.tasks)
          .map('standards')
          .flatten()
          .uniqBy('id')
          .value();

        return (
          <AttachmentDropzone
            mode={mode}
            setFieldValue={setFieldValue}
            initialAttachments={values.attachments}
          >
            {({ attachments, onRemove, isDragActive, openFileDialog }) => (
              <>
                {isDragActive && <AttachmentUploadOverlay />}
                <div className={styles.container}>
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      className={styles.backButton}
                      onClick={() => {
                        setIsAssignmentDialogOpened(true);
                        onClose();
                      }}
                    >
                      ← BACK: CHOOSE TYPE
                    </button>

                    <button
                      type="button"
                      className={styles.nextButton}
                      onClick={() => {
                        setSubmitAttempted(true);
                        handleSubmit();
                      }}
                      data-intercom-target="Create Edit Create Button"
                    >
                      NEXT: ASSIGN →
                    </button>
                  </div>

                  <div className={styles.navTabs}>
                    <button
                      type="button"
                      className={`${styles.tab} ${
                        activeTab === 'choose' ? styles.active : ''
                      }`}
                      onClick={() => setActiveTab('choose')}
                    >
                      Choose Type
                    </button>
                    <button
                      type="button"
                      className={`${styles.tab} ${
                        activeTab === 'create' ? styles.active : ''
                      }`}
                      onClick={() => setActiveTab('create')}
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      className={`${styles.tab} ${styles.tabProgress}`}
                    >
                      Assign
                    </button>
                  </div>

                  {errors.submit && (
                    <div className={styles.errorBanner}>{errors.submit}</div>
                  )}

                  <div
                    className={`${styles.formGroup} ${
                      showError('assignmentName') ? styles.hasError : ''
                    }`}
                  >
                    <input
                      type="text"
                      name="assignmentName"
                      value={values.assignmentName}
                      onChange={handleChange}
                      placeholder="Assignment Name"
                      className={
                        showError('assignmentName') ? styles.error : ''
                      }
                      data-intercom-target="Crete Edit Name Field"
                    />
                    {showError('assignmentName') && (
                      <div className={styles.errorText}>
                        {errors.assignmentName}
                      </div>
                    )}
                  </div>

                  <div className={styles.formGroup}>
                    <textarea
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Optional Description..."
                    />
                  </div>

                  <div className={styles.dropdownContainer}>
                    <div
                      className={`${styles.dropdown} ${
                        showError('rubric') ? styles.hasError : ''
                      }`}
                    >
                      <select
                        name="rubric"
                        value={values.rubric || ''}
                        onChange={handleChange}
                        className={showError('rubric') ? styles.error : ''}
                      >
                        <option value="" disabled>
                          Rubric
                        </option>
                        {VALUES_OPTIONS.map((option) => (
                          <option key={option.key} value={option.key}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div
                      className={`${styles.dropdown} ${
                        showError('pageType') ? styles.hasError : ''
                      }`}
                    >
                      {values.pageType === 'custom' ? (
                        <div className={styles.customInputWrapper}>
                          <input
                            type="number"
                            name="pageCount"
                            value={values.pageCount}
                            onChange={handleChange}
                            min="1"
                            placeholder="Enter"
                            className={`${styles.customInput} ${
                              showError('pageCount') ? styles.error : ''
                            }`}
                            disabled={
                              isFromTemplate &&
                              mode !== AssignmentDialogModes.COPY
                            }
                            onBlur={(e) => {
                              if (!e.target.value || e.target.value < 1) {
                                setFieldValue('pageType', '');
                                setFieldValue('pageCount', '');
                              }
                            }}
                          />
                          <span className={styles.pagesText}>Pages</span>
                        </div>
                      ) : (
                        <select
                          name="pageType"
                          value={values.pageType}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('pageType', value);
                            if (value !== 'custom') {
                              setFieldValue('pageCount', value);
                            }
                          }}
                          className={showError('pageType') ? styles.error : ''}
                          disabled={
                            isFromTemplate &&
                            mode !== AssignmentDialogModes.COPY
                          }
                        >
                          <option value="" disabled>
                            Pages
                          </option>
                          <option value="1">1 Page</option>
                          <option value="2">2 Pages</option>
                          <option value="3">3 Pages</option>
                          <option value="4">4 Pages</option>
                          <option value="custom">Custom</option>
                        </select>
                      )}
                    </div>
                  </div>

                  <div className={styles.standardsContainer}>
                    {assignmentStandards.map((standard, index) => (
                      <button
                        key={standard.id || index}
                        type="button"
                        className={styles.standardTag}
                      >
                        <span className={styles.standardCode}>
                          {standard.code}
                        </span>
                        {!(
                          isFromTemplate && mode !== AssignmentDialogModes.COPY
                        ) && (
                          <div
                            className={styles.closeButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              const updatedTasks = values.tasks.map((task) => ({
                                ...task,
                                standards: task.standards.filter(
                                  (s) => s.id !== standard.id
                                )
                              }));
                              setFieldValue('tasks', updatedTasks);
                            }}
                          >
                            <X size={14} />
                          </div>
                        )}
                      </button>
                    ))}
                    <button
                      type="button"
                      className={styles.addStandardBtn}
                      onClick={openStandardDialog}
                      disabled={
                        isFromTemplate && mode !== AssignmentDialogModes.COPY
                      }
                      data-intercom-target="Create Edit Standards Button"
                    >
                      <Plus size={14} />
                      ADD STANDARD
                    </button>
                  </div>

                  <div className={styles.advancedSettings}>
                    <button
                      type="button"
                      className={styles.header}
                      onClick={() => setShowAdvanced(!showAdvanced)}
                    >
                      <p>Advanced Settings</p>
                      <ChevronDown
                        size={16}
                        style={{
                          transform: showAdvanced ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    </button>

                    {showAdvanced && (
                      <div className={styles.content}>
                        <div className={styles.tabs}>
                          <button
                            type="button"
                            className={
                              activeSettingsTab === 'attachments'
                                ? styles.active
                                : ''
                            }
                            onClick={() => setActiveSettingsTab('attachments')}
                          >
                            Attachments
                          </button>
                        </div>

                        {activeSettingsTab === 'attachments' && (
                          <>
                            <p className={styles.description}>
                              Attach any materials connected to the assignment.
                            </p>

                            <div
                              className={styles.uploadArea}
                              onClick={openFileDialog}
                            >
                              <div className={styles.uploadButton}>
                                <Upload size={14} />
                                <span>ADD MATERIALS</span>
                              </div>
                            </div>

                            <AttachmentList
                              attachments={attachments}
                              onRemove={onRemove}
                            />
                          </>
                        )}

                        {activeSettingsTab === 'ai' && (
                          <div className={styles.aiSettings}>
                            {/* AI settings content can be added here */}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {isSubmitting && (
                    <div className={styles.loadingOverlay}>
                      <div className={styles.spinner} />
                    </div>
                  )}

                  {isStandardDialogOpened && (
                    <StandardDialogV2
                      selectedItems={assignmentStandards}
                      onClose={closeStandardDialog}
                      onSave={({ selectedStandards }) =>
                        onStandardsSelected({
                          selectedStandards,
                          formikTasks: values.tasks,
                          setFieldValue
                        })
                      }
                    />
                  )}
                  {isAssignmentDialogOpened && (
                    <Dialog
                      open={true}
                      alignTop={true}
                      onClose={() => setIsAssignmentDialogOpened(false)}
                      classes={{ paper: styles.assignmentCreateDialog }}
                    >
                      <TeacherAssignmentCreate />
                    </Dialog>
                  )}
                </div>
              </>
            )}
          </AttachmentDropzone>
        );
      }}
    </Formik>
  );
};

export default StepperAssignmentCreateForm;
