import _ from 'lodash';
import moment from 'moment';

import { flattenObjectives } from 'utils/trackers';

export const splitAssignmentsToMonths = ({ assignments }) => {
  const sortedAssignments = _.orderBy(assignments, 'due_at', 'desc').map(
    (assignment) => ({
      ...assignment,
      image: _.get(assignment, 'tasks.[0].work[0].work_url'),
      isEmptyWork: _.isEmpty(_.get(assignment, 'tasks.[0].work[0]')),
      month: moment(assignment.due_at).format('MMMM')
    })
  );

  return _.groupBy(sortedAssignments, 'month');
};

export const buildFormDataForCreate = ({ values }) => {
  const tasksWithStandardIds = _.map(values.tasks, (task) => ({
    ...task,
    standard_ids: task.standards.map((standard) => standard.id)
  }));

  const tasksWithOmittedStandards = _.map(tasksWithStandardIds, (task) =>
    _.omit(task, 'standards')
  ); // We do not need to pass the "standards" key to the BE

  const filteredObjectives = _.filter(
    flattenObjectives(tasksWithOmittedStandards),
    (objective) => !_.isEmpty(objective.name)
  );

  // Set `order` for each objective to be the consecutive index, since that's how we want them ordered.
  const objectives = _.map(filteredObjectives, (objective, index) => ({
    ...objective,
    order: index
  }));

  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description);

  _.forEach(values.attachments, (attachment) =>
    formData.append('attachment_file_ids', attachment.id)
  );

  _.forEach(objectives, (obj) =>
    formData.append('objectives', JSON.stringify(obj))
  );

  if (Object.keys(values).includes('isAcceleratedCurriculum')) {
    formData.append('is_accelerated', values.isAcceleratedCurriculum);
  }

  return formData;
};

export const buildStepperFormDataForCreate = ({ values }) => {
  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description || '');

  const standardIds = _.chain(values.tasks)
    .map('standards')
    .flatten()
    .map('id')
    .uniq()
    .value();

  const numberOfPages = parseInt(values.pageCount) || 1;

  for (let i = 0; i < numberOfPages; i++) {
    const objective = {
      name: `Page ${i + 1}`,
      value_type: values.rubric,
      depth: 1,
      description: '',
      children: [],
      standard_ids: standardIds,
      isHeader: true,
      order: i,
      disableEdit: false
    };

    formData.append('objectives', JSON.stringify(objective));
  }

  if (values.attachments?.length) {
    values.attachments.forEach((attachment) => {
      if (attachment.id) {
        formData.append('attachment_file_ids', attachment.id);
      }
    });
  }

  return formData;
};

export const buildFormDataForUpdate = ({ values }) => {
  const tasksWithStandardIds = _.map(values.tasks, (task) => ({
    ...task,
    standard_ids: task.standards.map((standard) => standard.id)
  }));

  const tasksWithOmittedStandards = _.map(tasksWithStandardIds, (task) =>
    _.omit(task, 'standards')
  ); // We do not need to pass the "standards" key to the BE

  const filteredObjectives = _.map(tasksWithOmittedStandards, (task) => {
    task.children = _.filter(
      task.children,
      (criteria) => !_.isEmpty(criteria.name)
    );
    return task;
  });

  let lastOrder = -1;
  const updatedTasks = _.map(filteredObjectives, (parent, index) => {
    const order = lastOrder + 1;
    const children = _.map(parent.children, (child, childIndex) => {
      const childOrder = order + childIndex + 1;
      return { ...child, order: childOrder };
    });

    lastOrder = order + children.length;

    return { ...parent, order, children };
  });

  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description);

  _.forEach(values.attachments, (attachment) =>
    formData.append('attachment_file_ids', attachment.id)
  );

  _.forEach(updatedTasks, (obj) =>
    formData.append('objectives', JSON.stringify(obj))
  );

  return formData;
};

export const buildStepperFormDataForUpdate = ({ values }) => {
  const tasksWithStandardIds = _.map(values.tasks, (task) => ({
    ...task,
    standard_ids: task.standards.map((standard) => standard.id)
  }));
  _.map(tasksWithStandardIds, (task) => _.omit(task, 'standards'));
  const numberOfPages = parseInt(values.pageCount) || 1;
  let objectives = [];

  for (let i = 0; i < numberOfPages; i++) {
    let objective;
    if (values.tasks[i]) {
      objective = values.tasks[i];
    } else {
      objective = {
        name: `Page ${i + 1}`,
        value_type: values.rubric || 'rubric_zero_three',
        depth: 1,
        description: '',
        children: [],
        standard_ids: _.chain(values.tasks)
          .map('standards')
          .flatten()
          .map('id')
          .uniq()
          .value(),
        isHeader: true,
        order: i,
        disableEdit: false
      };
    }

    objectives.push(objective);
  }

  let lastOrder = -1;
  const updatedObjectives = _.map(objectives, (objective, index) => {
    const order = lastOrder + 1;
    const children = _.map(objective.children, (child, childIndex) => {
      const childOrder = order + childIndex + 1;
      return { ...child, order: childOrder };
    });

    lastOrder = order + children.length;

    return { ...objective, order, children };
  });

  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description || '');

  _.forEach(updatedObjectives, (obj) =>
    formData.append('objectives', JSON.stringify(obj))
  );

  if (values.attachments?.length) {
    values.attachments.forEach((attachment) => {
      if (attachment.id) {
        formData.append('attachment_file_ids', attachment.id);
      }
    });
  }

  return formData;
};
