import React, { useContext, useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import { remappedRubricsToChecks } from 'pages/Pathways/utils';
import StudentSummaryDialogInsightsV2 from 'pages/Teachers/InsightsV2/components/StudentSummaryDialog';
import { StudentWorkCard as SharedStudentWorkCard } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as InfoIcon } from './assets/info.svg';
import styles from './styles.module.scss';

const MULTIPAGE_TAGGING_ENABLED =
  process.env.REACT_APP_MULTIPAGE_TAGGING_ENABLED === 'true';

const StudentWorkCard = ({
  workTagImageIds,
  studentName,
  studentWorkUrls,
  rubrics,
  setRubrics,
  setDialogIsOpen,
  refetchStrategiesData
}) => {
  const { user } = useContext(UserContext);
  const [summaryDialogIsOpen, setSummaryDialogIsOpen] = useState(false);

  return (
    <>
      <StudentSummaryDialogInsightsV2
        misconceptionName={''}
        studentName={studentName}
        workTagImageId={workTagImageIds[0]}
        workTagImageIds={MULTIPAGE_TAGGING_ENABLED ? workTagImageIds : []}
        refetchOverview={refetchStrategiesData}
        isSummaryDialogIsOpen={summaryDialogIsOpen}
        onClose={(e) => {
          setSummaryDialogIsOpen(false);
          trackMixpanelEvent(
            user.id,
            '[StrategiesOverview] Teacher closed student work info card.'
          );
          e.stopPropagation();
        }}
      />

      <SharedStudentWorkCard
        imageUrl={studentWorkUrls[0]}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSummaryDialogIsOpen(true);

          trackMixpanelEvent(
            user.id,
            '[StrategiesOverview] Teacher opened student work info card.'
          );
        }}
      >
        <div className={styles.workCardDiv}>
          <div className={styles.studentNameText}>{studentName}</div>
          {!_.isEmpty(rubrics) && (
            <Tooltip
              classes={{
                tooltip: styles.tooltipInfo,
                arrow: styles.tooltipArrow
              }}
              title={
                <Typography
                  variant="B-Text-3"
                  component="h5"
                  classes={{ root: styles.tooltipText }}
                >
                  {rubrics.map(({ rubric_name, value }, index) => (
                    <span key={index} className={styles.dialogText}>
                      {remappedRubricsToChecks({
                        masteryLevel: value,
                        color: '#fff'
                      })}{' '}
                      {rubric_name}
                    </span>
                  ))}
                </Typography>
              }
              placement="right"
              arrow={true}
            >
              <SvgIcon
                component={InfoIcon}
                className={styles.infoIcon}
                onMouseEnter={() => {
                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher hovered tooltip info.'
                  );
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRubrics(rubrics);
                  setDialogIsOpen(true);

                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher opened tooltip info.'
                  );
                }}
              />
            </Tooltip>
          )}
        </div>
      </SharedStudentWorkCard>
    </>
  );
};

export default StudentWorkCard;
